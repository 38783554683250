/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class SessionService {
  /**
   * Logs in and returns the authentication cookie.
   */
  static sessionPost(
    params: {
      /** requestBody */
      body?: LoginRequestSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<inline_response_200> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/session/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Logs current user out
   */
  static sessionDelete(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/session/';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SettingsService {
  /**
   * Get user notifications settings.
   */
  static systemSettingsGet(options: IRequestOptions = {}): Promise<SystemWideSettingsSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/settings/system/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get user notifications settings.
   */
  static systemSettingsPatch(
    params: {
      /** requestBody */
      body?: SystemWideSettingsSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SystemWideSettingsSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/settings/system/';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get user notifications settings.
   */
  static notificationsSettingsGet(options: IRequestOptions = {}): Promise<UserNotificationSettingsSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/settings/notifications/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update user notifications settings.
   */
  static notificationsSettingsPatch(
    params: {
      /** requestBody */
      body?: UserNotificationSettingsSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/settings/notifications/';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UserService {
  /**
   * Get all registered users from a server.
   */
  static usersGet(options: IRequestOptions = {}): Promise<UserAdminSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Post new user on a server. Can be accessed without login
   */
  static userRegistration(
    params: {
      /** requestBody */
      body?: UserRegistrationSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static userUserIdGet(
    params: {
      /** UUID of a user to get. */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/{userID}/';
      url = url.replace('{userID}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static usersPatch(
    params: {
      /** UUID of a user to get. */
      userId: string;
      /** requestBody */
      body?: UserAdminSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/{userID}/';
      url = url.replace('{userID}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static usersMfaPost(
    params: {
      /** UUID of a user to create mfa on */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserMFASchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/{userID}/mfa/';
      url = url.replace('{userID}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static usersMfaDelete(
    params: {
      /** UUID of a user to delete mfa from */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/{userID}/mfa/';
      url = url.replace('{userID}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static usersMfaConfirmPost(
    params: {
      /** UUID of a user to create mfa on */
      userId: string;
      /** requestBody */
      body?: MfaConfirmation;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/{userID}/mfa/confirm/';
      url = url.replace('{userID}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UserGroupService {
  /**
   * Set user groups for the user
   */
  static setUserGroupsForUser(
    params: {
      /** UUID of the user */
      userId: UUIDSchema;
      /** requestBody */
      body?: SetUnsetUserGroupsForUserSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAdminSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/{userID}/set_user_groups/';
      url = url.replace('{userID}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Unset user groups for the user
   */
  static unsetUserGroupsForUser(
    params: {
      /** UUID of the user */
      userId: UUIDSchema;
      /** requestBody */
      body?: SetUnsetUserGroupsForUserSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserAdminSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/{userID}/unset_user_groups/';
      url = url.replace('{userID}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get a list of user groups(only admin).
   */
  static getUserGroups(options: IRequestOptions = {}): Promise<UserGroupResponseSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user_groups/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create new user group(only admin).
   */
  static postUserGroup(
    params: {
      /** requestBody */
      body?: UserGroupCreateEditSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserGroupResponseSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user_groups/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete the user group(only admin)
   */
  static deleteUserGroup(
    params: {
      /** ID of the user group */
      userGroupId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user_groups/{userGroupID}/';
      url = url.replace('{userGroupID}', params['userGroupId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update the user group(only admin).
   */
  static patchUserGroup(
    params: {
      /** ID of the user group */
      userGroupId: UUIDSchema;
      /** requestBody */
      body?: UserGroupCreateEditSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserGroupResponseSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user_groups/{userGroupID}/';
      url = url.replace('{userGroupID}', params['userGroupId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Set users into the user group(only admin).
   */
  static setUsersIntoUserGroup(
    params: {
      /** ID of the user group */
      userGroupId: UUIDSchema;
      /** requestBody */
      body?: SetUnsetUsersIntoUserGroupSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserGroupResponseSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user_groups/{userGroupID}/set_users/';
      url = url.replace('{userGroupID}', params['userGroupId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Unset users into the user group(only admin).
   */
  static unsetUsersFromUserGroup(
    params: {
      /** ID of the user group */
      userGroupId: UUIDSchema;
      /** requestBody */
      body?: SetUnsetUsersIntoUserGroupSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserGroupResponseSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/user_groups/{userGroupID}/unset_users/';
      url = url.replace('{userGroupID}', params['userGroupId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BotUserService {
  /**
   * Get list of bot users
   */
  static botUserList(options: IRequestOptions = {}): Promise<BotUserSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/bot_users/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Post new bot user on a server
   */
  static botUserRegistration(
    params: {
      /** requestBody */
      body?: BotUserRegistrationSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BotUserTokenSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/bot_users/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete bot user
   */
  static botUserDelete(
    params: {
      /** UUID of a bot user to delete */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BotUserSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/bot_users/{userID}/';
      url = url.replace('{userID}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Generate new token for bot-user
   */
  static botUserPatchToken(
    params: {
      /** UUID of a bot user */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BotUserTokenSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/bot_users/{userID}/token/';
      url = url.replace('{userID}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProjectService {
  /**
   * Get accessible projects.
   */
  static projectsGet(options: IRequestOptions = {}): Promise<ProjectSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create new project
   */
  static projectsPost(
    params: {
      /** requestBody */
      body?: CreateNewProjectSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get various system-wide projects settings
   */
  static projectsSettingsGet(options: IRequestOptions = {}): Promise<ProjectSettingsSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/settings/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create brand new project Hawser connection
   */
  static projectConnectionPost(
    params: {
      /**  */
      projectId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/connections/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete existing Hawser connection
   */
  static projectConnectionDelete(
    params: {
      /**  */
      projectId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/connections/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Hawser connection statuses
   */
  static projectConnectionStatus(
    params: {
      /**  */
      projectId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/connections/status/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get project by ID.
   */
  static projectGet(
    params: {
      /**  */
      projectId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete project.
   */
  static projectDelete(
    params: {
      /**  */
      projectId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Edit project.
   */
  static projectPatch(
    params: {
      /**  */
      projectId: UUIDSchema;
      /** requestBody */
      body?: ProjectSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all project issues.
   */
  static projectIssuesGet(
    params: {
      /**  */
      projectId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BasicIssueSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/issues/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get project issues based on provided filters.
   */
  static projectIssuesFilter(
    params: {
      /**  */
      projectId: UUIDSchema;
      /**  */
      params: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BasicIssueSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/issues/filter';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { params: params['params'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static projectUsersGet(
    params: {
      /**  */
      projectId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectPermissionViewSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project/{projectID}/users/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static projectUsersPost(
    params: {
      /**  */
      projectId: UUIDSchema;
      /** requestBody */
      body?: ProjectPermissionSetSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project/{projectID}/users/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static projectUsersPatch(
    params: {
      /**  */
      projectId: UUIDSchema;
      /** requestBody */
      body?: ProjectPermissionUpdateSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project/{projectID}/users/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static projectUsersDelete(
    params: {
      /**  */
      projectId: UUIDSchema;
      /** User ID to revoke permission from */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project/{projectID}/users/{userID}/';
      url = url.replace('{projectID}', params['projectId'] + '');
      url = url.replace('{userID}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get existing project checklist
   */
  static projectChecklistGet(
    params: {
      /**  */
      projectId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project/{projectID}/checklist/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get project issue statuses list
   */
  static projectStatusGet(
    params: {
      /**  */
      projectId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IssueStatusSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project/{projectID}/status/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create new project status
   */
  static projectStatusPost(
    params: {
      /**  */
      projectId: UUIDSchema;
      /** requestBody */
      body?: IssueStatusSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IssueStatusSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project/{projectID}/status/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete existing issue status
   */
  static projectStatusDelete(
    params: {
      /**  */
      projectId: UUIDSchema;
      /** Project-wide status Name */
      statusName: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project/{projectID}/status/{statusName}';
      url = url.replace('{projectID}', params['projectId'] + '');
      url = url.replace('{statusName}', params['statusName'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update existing issue status
   */
  static projectStatusPatch(
    params: {
      /**  */
      projectId: UUIDSchema;
      /** Status technical name */
      statusName: string;
      /** requestBody */
      body?: IssueStatusSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IssueStatusSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project/{projectID}/status/{statusName}';
      url = url.replace('{projectID}', params['projectId'] + '');
      url = url.replace('{statusName}', params['statusName'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get issue custom schema
   */
  static getIssueCustomSchema(
    params: {
      /**  */
      projectId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IssueCustomSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/issue_schema/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SlaService {
  /**
   * Get information about sla global setting
   */
  static getGlobalSlaSetting(options: IRequestOptions = {}): Promise<SLASettingWithDefaultSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/sla_config/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create or update global sla setting
   */
  static postGlobalSlaSetting(
    params: {
      /** requestBody */
      body?: SLASettingSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SLASettingSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/sla_config/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get information about sla setting
   */
  static getProjectSlaSetting(
    params: {
      /**  */
      projectId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SLASettingWithDefaultSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/sla/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create or update sla setting for project
   */
  static postProjectSlaSetting(
    params: {
      /** UUID of a project to get. */
      projectId: UUIDSchema;
      /** requestBody */
      body?: SLASettingSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SLASettingSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/sla/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete sla setting for project
   */
  static deleteProjectSlaSetting(
    params: {
      /** UUID of a project to get. */
      projectId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/sla/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class IntegrationService {
  /**
   * Get list of project integrations with external services
   */
  static projectIntegrationsGet(
    params: {
      /**  */
      projectId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectIntegrationSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/integrations/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Add new external service integration
   */
  static projectIntegrationsPost(
    params: {
      /**  */
      projectId: UUIDSchema;
      /** requestBody */
      body?: ProjectIntegrationSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectIntegrationSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/integrations/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Jira comments by an export record id
   */
  static getJiraComments(
    params: {
      /**  */
      exportRecordId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JiraCommentSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/jira/export_record/{exportRecordID}/comments';
      url = url.replace('{exportRecordID}', params['exportRecordId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Add jira comment by an export record id
   */
  static postJiraComment(
    params: {
      /**  */
      exportRecordId: UUIDSchema;
      /** requestBody */
      body?: JiraCommentSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JiraCommentSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/jira/export_record/{exportRecordID}/comments';
      url = url.replace('{exportRecordID}', params['exportRecordId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Deleting Jira comment by an export record id and an jira comment id
   */
  static deleteJiraComment(
    params: {
      /**  */
      exportRecordId: UUIDSchema;
      /**  */
      jiraCommentId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JiraCommentSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/jira/export_record/{exportRecordID}/comments/{jiraCommentID}';
      url = url.replace('{exportRecordID}', params['exportRecordId'] + '');
      url = url.replace('{jiraCommentID}', params['jiraCommentId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Updating Jira comment by an export record id and an jira comment id
   */
  static patchJiraComment(
    params: {
      /**  */
      exportRecordId: UUIDSchema;
      /**  */
      jiraCommentId: UUIDSchema;
      /** requestBody */
      body?: JiraCommentSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JiraCommentSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/jira/export_record/{exportRecordID}/comments/{jiraCommentID}';
      url = url.replace('{exportRecordID}', params['exportRecordId'] + '');
      url = url.replace('{jiraCommentID}', params['jiraCommentId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Jira application version/authentication method by hostname
   */
  static getJiraApplicationVersion(
    params: {
      /** requestBody */
      body?: JiraApplicationInfoRequestSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JiraApplicationInfoResponseSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/jira/info/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get Jira integration instance meta information about projects and issues
   */
  static projectJiraIntegrationsMeta(
    params: {
      /** UUID of integration to get */
      integrationId: string;
      /** Jira project key to specify */
      projectKey: string;
      /** Do not filter results by projectKey and issueType */
      initialQuery: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JiraMetaInfoResponseSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/jira/{integrationID}/meta/';
      url = url.replace('{integrationID}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { projectKey: params['projectKey'], initialQuery: params['initialQuery'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Remove existing Jira integration.
   */
  static projectJiraIntegrationDelete(
    params: {
      /** UUID of integration to get */
      integrationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/jira/{integrationID}/';
      url = url.replace('{integrationID}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Set configuration values for existing Jira integration.
   */
  static projectJiraIntegrationPatch(
    params: {
      /** UUID of integration to get */
      integrationId: string;
      /** requestBody */
      body?: JiraIntegrationSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JiraIntegrationSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/jira/{integrationID}/';
      url = url.replace('{integrationID}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Export list of issues to external Jira project.
   */
  static exportIssuesToJira(
    params: {
      /** UUID of integration to get */
      integrationId: string;
      /** requestBody */
      body?: JiraIntegrationExportSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExportedIssuesSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/jira/{integrationID}/export/';
      url = url.replace('{integrationID}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Preview issues that are about to be sent to jira. Check if we have required default fields
   */
  static exportIssuesPreview(
    params: {
      /** UUID of integration to get */
      integrationId: string;
      /** requestBody */
      body?: body_1;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/jira/{integrationID}/export/preview/';
      url = url.replace('{integrationID}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Preview issues that are about to be sent to jira. Check if we have required default fields
   */
  static searchItemsJira(
    params: {
      /** UUID of integration to get */
      integrationId: string;
      /** type of fields for searching by (can be used from requiredFields -> field -> name ) */
      field: string;
      /** Query string (can be user's name, email, etc.) */
      query: string;
      /** Jira project key to specify search search */
      projectKey: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JiraSearchResponseSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/jira/{integrationID}/search/';
      url = url.replace('{integrationID}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { field: params['field'], query: params['query'], projectKey: params['projectKey'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Search Jira's issues
   */
  static searchIssuesJira(
    params: {
      /** IntegrationID which must be used for linking */
      integrationId: string;
      /** Query string (summary, issue key) */
      query: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JiraSearchIssuesResponseSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/jira/{integrationID}/search/issues/';
      url = url.replace('{integrationID}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { query: params['query'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Link issues with external Jira issue
   */
  static linkLocalWithExternalJiraIssue(
    params: {
      /** IntegrationID which must be used for linking */
      integrationId: UUIDSchema;
      /** requestBody */
      body?: JiraIntegrationLinkingSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExportedIssuesSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/jira/{integrationID}/link_issue/';
      url = url.replace('{integrationID}', params['integrationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get accessible integration servers.
   */
  static integrationServerListGet(options: IRequestOptions = {}): Promise<IntegrationServerSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/servers/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Creates new server usable for integrations (ex.: as relation for JiraIntegration)
   */
  static integrationServerPost(
    params: {
      /** requestBody */
      body?: IntegrationServerSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IntegrationServerSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/servers/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get detailed integration server (with related integrations).
   */
  static integrationServerDetailsGet(
    params: {
      /** Server Instance ID */
      serverId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IntegrationServerDetailsSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/servers/{serverID}/';
      url = url.replace('{serverID}', params['serverId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Deletes requested server (can affect all related integrations)
   */
  static integrationServerDelete(
    params: {
      /** Server Instance ID */
      serverId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/servers/{serverID}/';
      url = url.replace('{serverID}', params['serverId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Updates requested server (can affect all related integrations)
   */
  static integrationServerPatch(
    params: {
      /** Server Instance ID */
      serverId: UUIDSchema;
      /** requestBody */
      body?: IntegrationServerSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IntegrationServerDetailsSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/servers/{serverID}/';
      url = url.replace('{serverID}', params['serverId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Generate new token for integration server
   */
  static integrationServerRegenerateToken(
    params: {
      /** Server Instance ID */
      serverId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IntegrationServerDetailsSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/servers/{serverID}/regenerate_token/';
      url = url.replace('{serverID}', params['serverId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Webhook JIRA Handler: allows syncing external issue (already exported) with local one
   */
  static integrationWebhookHandlerPost(
    params: {
      /** Token from the Integration server */
      token: UUIDSchema;
      /** requestBody */
      body?: JiraWebhookRequestSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/integrations/servers/{token}/webhook/';
      url = url.replace('{token}', params['token'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class IssueService {
  /**
   * Create or update user permissions
   */
  static issuesPermissionsBulkChange(
    params: {
      /**  */
      projectId: UUIDSchema;
      /** requestBody */
      body?: IssuePermissionsBulkSetSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/issues/users/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete user permissions
   */
  static issuesPermissionsBulkDelete(
    params: {
      /**  */
      projectId: UUIDSchema;
      /** requestBody */
      body?: IssuePermissionsBulkDeleteSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/issues/users/delete/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get all issues (cross-project)
   */
  static issuesByFilter(
    params: {
      /**  */
      offset: number;
      /**  */
      limit: number;
      /** requestBody */
      body?: IssuesFilterSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<inline_response_200_1> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/issues/filter/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { offset: params['offset'], limit: params['limit'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get issue by ID
   */
  static issueGet(
    params: {
      /** ID of a issue to get. */
      issueId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BasicIssueSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/issues/{issueID}/';
      url = url.replace('{issueID}', params['issueId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete issue by ID
   */
  static issueDelete(
    params: {
      /** ID of a issue to get. */
      issueId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/issues/{issueID}/';
      url = url.replace('{issueID}', params['issueId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Patch issue status by ID
   */
  static issuePatch(
    params: {
      /** ID of a issue to get. */
      issueId: UUIDSchema;
      /** requestBody */
      body?: IssueStatusPatchSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BasicIssueSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/issues/{issueID}/';
      url = url.replace('{issueID}', params['issueId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get message list
   */
  static getChatMessages(
    params: {
      /** ID of a issue. */
      issueId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChatMessageViewSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/issues/{issueID}/messages/';
      url = url.replace('{issueID}', params['issueId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Send message to issue chat.
   */
  static postChatMessage(
    params: {
      /** ID of a issue. */
      issueId: UUIDSchema;
      /** requestBody */
      body?: ChatMessagePostSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/issues/{issueID}/messages/';
      url = url.replace('{issueID}', params['issueId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get users assigned to issue
   */
  static issuePermissionsGet(
    params: {
      /** ID of a issue to get. */
      issueId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IssuePermissionViewSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/issues/{issueID}/users/';
      url = url.replace('{issueID}', params['issueId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Assign user to issue
   */
  static issuePermissionsPost(
    params: {
      /** ID of a issue to get. */
      issueId: UUIDSchema;
      /** requestBody */
      body?: IssuePermissionSetSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/issues/{issueID}/users/';
      url = url.replace('{issueID}', params['issueId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Change assigned user issue permissions
   */
  static issuePermissionsPatch(
    params: {
      /** ID of a issue to get. */
      issueId: string;
      /** requestBody */
      body?: IssuePermissionUpdateSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/issues/{issueID}/users/';
      url = url.replace('{issueID}', params['issueId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Revoke user assignment to issue
   */
  static issuePermissionsDelete(
    params: {
      /** ID of a issue to get. */
      issueId: UUIDSchema;
      /** User ID to revoke permission from */
      userId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/issues/{issueID}/users/{userID}/';
      url = url.replace('{issueID}', params['issueId'] + '');
      url = url.replace('{userID}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get history of issue status changes
   */
  static issueStatusChanges(
    params: {
      /** ID of a issue to get. */
      issueId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IssueStatusChangeEntrySchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/issues/{issueID}/status_changes';
      url = url.replace('{issueID}', params['issueId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get issue attachment
   */
  static filesGet(
    params: {
      /** ID of a file to get. */
      fileId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/files/{fileID}/';
      url = url.replace('{fileID}', params['fileId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ExportService {
  /**
   * Export issues - download the file (CSV)
   */
  static issuesExport(
    params: {
      /** requestBody */
      body?: ExportCSVRequestSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/issues/export/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Export issues - get meta (exporting) info
   */
  static issuesExportMeta(
    params: {
      /** requestBody */
      body?: ExportCSVMetaRequestSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExportCSVMetaInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/issues/export/meta/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DashboardService {
  /**
   * Get user's dashboard (cross-project)
   */
  static dashboardGet(options: IRequestOptions = {}): Promise<DashboardSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Edit user's dashboard (cross-project)
   */
  static dashboardPatch(
    params: {
      /** requestBody */
      body?: DashboardSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard/';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create user dashboard's card (cross-project)
   */
  static dashboardCardsCreate(
    params: {
      /** requestBody */
      body?: DashboardCardSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DashboardCardWithValueSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard/cards/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Validate user dashboard's card (cross-project)
   */
  static dashboardCardValidate(
    params: {
      /** requestBody */
      body?: DashboardCardDraftSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DashboardCardWithValueSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard/cards/draft/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get user dashboard's card info (cross-project)
   */
  static dashboardCardGet(
    params: {
      /**  */
      cardId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DashboardCardWithValueSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard/cards/{cardID}/';
      url = url.replace('{cardID}', params['cardId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete user dashboard's card (cross-project)
   */
  static dashboardCardDelete(
    params: {
      /**  */
      cardId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard/cards/{cardID}/';
      url = url.replace('{cardID}', params['cardId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Edit user dashboard's card (cross-project).
   */
  static dashboardCardPatch(
    params: {
      /**  */
      cardId: number;
      /** requestBody */
      body?: DashboardCardSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DashboardCardWithValueSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/dashboard/cards/{cardID}/';
      url = url.replace('{cardID}', params['cardId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get project's dashboard
   */
  static projectDashboardGet(
    params: {
      /**  */
      projectId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DashboardSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/dashboard/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Edit project's dashboard
   */
  static projectDashboardPatch(
    params: {
      /**  */
      projectId: UUIDSchema;
      /** requestBody */
      body?: DashboardSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/dashboard/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get project's dashboard cards
   */
  static projectDashboardCardsBulkGet(
    params: {
      /**  */
      projectId: UUIDSchema;
      /**  */
      params: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DashboardCardWithValueSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/dashboard/cards/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { params: params['params'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create project's dashboard card
   */
  static projectDashboardCardsCreate(
    params: {
      /**  */
      projectId: UUIDSchema;
      /** requestBody */
      body?: DashboardCardSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DashboardCardWithValueSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/dashboard/cards/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Validate project's dashboard card
   */
  static projectDashboardCardValidate(
    params: {
      /**  */
      projectId: UUIDSchema;
      /** requestBody */
      body?: DashboardCardDraftSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DashboardCardWithValueSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/dashboard/cards/draft/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get project's dashboard card
   */
  static projectDashboardCardGet(
    params: {
      /**  */
      projectId: UUIDSchema;
      /** Card ID. */
      cardId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DashboardCardWithValueSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/dashboard/cards/{cardID}/';
      url = url.replace('{projectID}', params['projectId'] + '');
      url = url.replace('{cardID}', params['cardId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete project's dashboard card
   */
  static projectDashboardCardDelete(
    params: {
      /**  */
      projectId: UUIDSchema;
      /**  */
      cardId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/dashboard/cards/{cardID}/';
      url = url.replace('{projectID}', params['projectId'] + '');
      url = url.replace('{cardID}', params['cardId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Edit project's dashboard card
   */
  static projectDashboardCardPatch(
    params: {
      /**  */
      projectId: UUIDSchema;
      /** Card ID. */
      cardId: number;
      /** requestBody */
      body?: DashboardCardSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DashboardCardWithValueSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/projects/{projectID}/dashboard/cards/{cardID}/';
      url = url.replace('{projectID}', params['projectId'] + '');
      url = url.replace('{cardID}', params['cardId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProjectGroupService {
  /**
   * Get a tree of project groups
   */
  static getProjectGroupsTree(options: IRequestOptions = {}): Promise<ProjectGroupSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project_groups/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Creating a new project group
   */
  static postProjectGroup(
    params: {
      /** requestBody */
      body?: ProjectGroupSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectGroupSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project_groups/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get project group by id
   */
  static getProjectGroup(
    params: {
      /**  */
      projectGroupId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectGroupSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project_groups/{projectGroupID}';
      url = url.replace('{projectGroupID}', params['projectGroupId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Deleting project group
   */
  static deleteProjectGroup(
    params: {
      /**  */
      projectGroupId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project_groups/{projectGroupID}';
      url = url.replace('{projectGroupID}', params['projectGroupId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Editing project group
   */
  static patchProjectGroup(
    params: {
      /**  */
      projectGroupId: UUIDSchema;
      /** requestBody */
      body?: ProjectGroupSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectGroupSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/project_groups/{projectGroupID}';
      url = url.replace('{projectGroupID}', params['projectGroupId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class NotificationsService {
  /**
   * Get paginated list of user notification records.
   */
  static notificationsListGet(options: IRequestOptions = {}): Promise<NotificationEventSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/notifications/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Mark notification event as read.
   */
  static notificationMarkRead(
    params: {
      /** UUID of a notification. */
      notificationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/notifications/{notificationID}/read/';
      url = url.replace('{notificationID}', params['notificationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ConveyService {
  /**
 * Accept Convey AMQP message and create record. Note: internal use only. Inaccessible for Web UI.

 */
  static conveyMessagePost(
    params: {
      /** requestBody */
      body?: ConveyMessageSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/convey/message/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
 * Accept Convey report and create record. Note: internal use only. Inaccessible for Web UI.

 */
  static conveyReportPost(
    params: {
      /** requestBody */
      body?: ConveyReportSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/convey/report/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ServiceService {
  /**
   * Check if Frigate service is up
   */
  static pingGet(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/service/ping/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Check if Frigate-dependant services is up
   */
  static healthGet(options: IRequestOptions = {}): Promise<HealthResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/service/health/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get various server-related info for client
   */
  static serverInfo(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/service/info/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get existing LDAP authentication config
   */
  static ldapConfigGet(options: IRequestOptions = {}): Promise<LDAPAuthenticationConfigSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/service/ldap/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create new LDAP authentication config
   */
  static ldapConfigPost(
    params: {
      /** requestBody */
      body?: LDAPAuthenticationConfigSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LDAPAuthenticationConfigSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/service/ldap/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete LDAP authentication config
   */
  static ldapConfigDelete(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/service/ldap/';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update LDAP authentication config
   */
  static ldapConfigPatch(
    params: {
      /** requestBody */
      body?: LDAPAuthenticationConfigSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LDAPAuthenticationConfigSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/service/ldap/';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Test LDAP authentication config without creation
   */
  static ldapConfigTest(
    params: {
      /** requestBody */
      body?: LDAPAuthenticationConfigSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<inline_response_200_2> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/service/ldap/test/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get list of existing issue status templates
   */
  static statusTemplatesGet(options: IRequestOptions = {}): Promise<IssueStatusTemplateSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/service/status_templates/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create new issue status template
   */
  static statusTemplatesPost(
    params: {
      /** requestBody */
      body?: IssueStatusTemplateSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IssueStatusTemplateSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/service/status_templates/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete existing issue status template.
   */
  static statusTemplateDelete(
    params: {
      /** ID of a status template to patch. */
      templateId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/service/status_templates/{templateID}/';
      url = url.replace('{templateID}', params['templateId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Edit existing issue status template
   */
  static statusTemplatePatch(
    params: {
      /** ID of a status template to patch. */
      templateId: string;
      /** requestBody */
      body?: IssueStatusTemplateSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IssueStatusTemplateSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/service/status_templates/{templateID}/';
      url = url.replace('{templateID}', params['templateId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get existing SMTP authentication config
   */
  static smtpConfigGet(options: IRequestOptions = {}): Promise<SmtpConfigSchemaResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/service/smtp_config/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create new SMTP authentication config
   */
  static smtpConfigPost(
    params: {
      /** requestBody */
      body?: SmtpConfigSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SmtpConfigSchemaResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/service/smtp_config/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete SMTP authentication config
   */
  static smtpConfigDelete(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/service/smtp_config/';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update already an existing SMTP authentication config
   */
  static smtpConfigPatch(
    params: {
      /** requestBody */
      body?: SmtpConfigSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SmtpConfigSchemaResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/service/smtp_config/';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendTestEmail(
    params: {
      /** requestBody */
      body?: EmailSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/service/smtp_config/test_email/';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUserRoles(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/service/user_roles/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getHawserStatusesByEntity(
    params: {
      /** Entity name for requesting hawser connection statuses */
      entity: string;
      /**  */
      hawserId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HawserStatusSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/service/hawser_statuses/{hawserID}/';
      url = url.replace('{hawserID}', params['hawserId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { entity: params['entity'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ReportTemplateService {
  /**
   * Get all report templates (with no template's content)
   */
  static reportTemplatesGet(options: IRequestOptions = {}): Promise<ReportTemplateSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/report_template/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Add new report template
   */
  static reportTemplatePost(options: IRequestOptions = {}): Promise<ReportTemplateSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/report_template/';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get report template
   */
  static reportTemplateGet(
    params: {
      /** ID of a report template to get. */
      templateId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/report_template/{templateID}/';
      url = url.replace('{templateID}', params['templateId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete existing report template
   */
  static reportTemplateDelete(
    params: {
      /** ID of a report template to get. */
      templateId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/report_template/{templateID}/';
      url = url.replace('{templateID}', params['templateId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ReportService {
  /**
   * Check access to report generator
   */
  static reportCheckPermission(
    params: {
      /**  */
      projectId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InformationParameterSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/report/{projectID}/statistic/';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Inform about successful report generation
   */
  static reportInformSuccess(
    params: {
      /**  */
      projectId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InformationParameterSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/report/{projectID}';
      url = url.replace('{projectID}', params['projectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LicenseService {
  /**
   * Get current license information
   */
  static licenseGet(options: IRequestOptions = {}): Promise<LicenseSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/license/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Post new license
   */
  static licensePost(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/license/';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Delete license
   */
  static licenseDelete(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/license/';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get information about all restrictions
   */
  static licenseInformation(options: IRequestOptions = {}): Promise<LicenseInformationSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/license/information/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get information about all restrictions before license expired
   */
  static licenseInformationExpired(options: IRequestOptions = {}): Promise<LicenseInformationSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/license/information/expired/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ApplicationConnectService {
  /**
   * Get all application connects(only admins)
   */
  static getApplicationConnects(options: IRequestOptions = {}): Promise<ApplicationConnectWithAggregationSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/application_connects/';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Creating a new application connect(only admins)
   */
  static postApplicationConnect(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/application_connects/';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteApplicationConnect(
    params: {
      /** ID of an application connect */
      applicationConnectId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/application_connects/{applicationConnectID}/';
      url = url.replace('{applicationConnectID}', params['applicationConnectId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static patchApplicationConnect(
    params: {
      /** ID of an application connect */
      applicationConnectId: string;
      /** requestBody */
      body?: UpdatingApplicationConnectSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ApplicationConnectBaseSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/application_connects/{applicationConnectID}/';
      url = url.replace('{applicationConnectID}', params['applicationConnectId'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get created projects with application connect
   */
  static getProjectsByAppConnect(
    params: {
      /**  */
      applicationConnectId: UUIDSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProjectSchema[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/application_connects/{applicationConnectID}/projects/';
      url = url.replace('{applicationConnectID}', params['applicationConnectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Create project on hive via Application connect
   */
  static postProjectAppConnect(
    params: {
      /**  */
      applicationConnectId: UUIDSchema;
      /** requestBody */
      body?: AppConnectProjectSchema;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/application_connects/{applicationConnectID}/projects/';
      url = url.replace('{applicationConnectID}', params['applicationConnectId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Request the users list from opposer and getting them.
   */
  static getUsersListByAppConnect(
    params: {
      /**  */
      applicationConnectId: UUIDSchema;
      /** Flag to force a request for a list of users. */
      forceUpdate: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AppConnectResourceUsersSchema> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/application_connects/{applicationConnectID}/resources/users/';
      url = url.replace('{applicationConnectID}', params['applicationConnectId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { forceUpdate: params['forceUpdate'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface ProjectSchema {
  /** Project id in UUID4 format. */
  id?: string;

  /** Project name. */
  name?: string;

  /** Project description. */
  description?: string;

  /** Project start date. */
  startDate?: Date;

  /** Estimated project completion date. */
  completionDate?: Date;

  /** Project scope description. */
  scope?: string;

  /** Field meaning unknown */
  projectType?: string;

  /** Project Hawser connection ID */
  hawserID?: string;

  /** Project Hawser connection name */
  connectionName?: string;

  /** Last successful outgoing ping message for existing connection */
  lastOutgoingPing?: Date;

  /** Last successful incoming pong answer message for existing connection */
  lastIncomingPong?: Date;

  /**  */
  owner?: UserShortSchema;

  /** Current user project permission */
  permission?: string;

  /** List of users' ids which have an access to the project */
  teamUsers?: UUIDSchema[];

  /** Project group */
  groupID?: string;

  /**  */
  applicationConnect?: ApplicationConnectBaseSchema;
}

export interface ProjectSettingsSchema {
  /**  */
  statuses?: IssueStatusSchema;
}

export interface ProjectCheckListSchema {
  /**  */
  content?: object;
}

export interface ProjectIntegrationSchema {
  /** Project external integration type */
  integrationType: EnumProjectIntegrationSchemaIntegrationType;

  /**  */
  data: JiraIntegrationSchema;
}

export interface CreateNewProjectSchema {
  /**  */
  projectData?: ProjectSchema;

  /**  */
  localUsers?: ProjectPermissionSetSchema[];
}

export interface HealthResponse {
  /** Status of postgres server. */
  dataStorageReady?: boolean;

  /** Status of convey server. */
  conveyServiceReady?: boolean;

  /** Status of vault server. */
  vaultServiceReady?: boolean;
}

export interface IssueExportSchema {
  /**  */
  id?: string;

  /**  */
  integrationType?: string;

  /**  */
  integrationID?: string;

  /**  */
  integrationExists?: boolean;

  /**  */
  metaData?: object;

  /**  */
  created?: Date;
}

export interface JiraIntegrationSchema {
  /** Relation to integration's server (available via \/integrations\/servers\/) */
  serverID?: string;

  /** Custom integration name */
  name?: string;

  /** JIRA instance user email or login */
  username?: string;

  /**  JIRA user access token or password (provided as plain-text or vault-path (if set)<br> - example (plain): 94fad9dbad58 (will be null for response) <br> - example (vault): engine=kv-v1,mount_point=kv1-mount_point,path=my_secret,key=jira_pass  */
  token?: string;

  /** JIRA instance url hostname in 'schema:\/\/host\/' format */
  hostname?: string;

  /** Jira project unique key */
  jiraProjectKey?: string;

  /**  */
  jiraIssueType?: JiraIntegrationSchema_jiraIssueType;

  /** Export test issue after creation if `True` */
  sendTestIssue?: boolean;

  /** Enable backward synchronisation (changing issues on JIRA side affects changing issues on our side) or not */
  backwardSyncEnabled?: boolean;

  /** Enable synchronisation Jira comments(changing comments in and out of Jira) */
  syncCommentsEnabled?: boolean;

  /** Status mapping for matching external statuses with local. It is required property for integration with enabled backward's sync (backwardSyncEnabled=true) */
  backwardStatusMapping?: JiraIntegrationSchema_backwardStatusMapping[];

  /**  */
  fieldsDefaultValues?: JiraIntegrationSchema_fieldsDefaultValues[];

  /** Bot's user_id (will be used for detection issue's changes in notifications) */
  botUserID?: string;
}

export interface JiraIntegrationExportSchema {
  /**  */
  issues?: JiraIntegrationExportSchema_issues[];

  /**  */
  fieldsDefaultValues?: JiraIntegrationExportSchema_fieldsDefaultValues[];
}

export interface JiraIntegrationLinkingSchema {
  /**  */
  localIssueID?: UUIDSchema;

  /**  */
  jiraIssueKey?: string;
}

export interface ExportedIssuesSchema {
  /**  */
  localIssueID?: UUIDSchema;

  /** Not empty, if success only */
  key?: string;

  /**  */
  state?: EnumExportedIssuesSchemaState;

  /**  */
  url?: string;

  /** Not empty, if failed only */
  error?: string;

  /**  */
  exportRecord?: IssueExportSchema;
}

export interface JiraWebhookRequestSchema {
  /**  */
  issue?: JiraWebhookRequestSchema_issue;

  /**  */
  webhookEvent?: string;

  /**  */
  issue_event_type_name?: string;

  /**  */
  comment?: JiraWebhookRequestSchema_comment;
}

export interface JiraApplicationInfoRequestSchema {
  /**  */
  hostname?: string;
}

export interface JiraMetaInfoResponseSchema {
  /** List of available projects from integrated JIRA application */
  projects?: JiraMetaInfoResponseSchema_projects[];

  /** List of available statuses from integrated JIRA application */
  externalStatuses?: JiraMetaInfoResponseSchema_externalStatuses[];

  /** List of available statuses from curren local context (project) */
  localStatuses?: IssueStatusSchema[];

  /** List of dictionaries containing required fields and their possible values */
  requiredFields?: JiraMetaFieldsSchema[];

  /** List of dictionaries containing not-required fields and their possible values */
  nonRequiredFields?: JiraMetaFieldsSchema[];
}

export interface JiraApplicationInfoResponseSchema {
  /** Jira application authentication method based on version */
  authentication?: EnumJiraApplicationInfoResponseSchemaAuthentication;
}

export interface JiraSearchResponseSchema {
  /** Jira's instance ID (depends on context, can be real instance ID or issue's) */
  id?: string;

  /** Jira instance's name (or summary) */
  displayName?: string;
}

export interface JiraSearchIssuesResponseSchema {
  /** Jira's instance ID (depends on context, can be real instance ID or issue's) */
  id?: string;

  /** Jira instance's name (or summary) */
  displayName?: string;

  /** Jira issue key */
  key?: string;

  /** Jira issue's summary */
  summary?: string;

  /** Shows whether the Jira's issue is linked to apiary */
  alreadyLinked?: boolean;
}

export interface JiraMetaFieldsSchema {
  /**  */
  name?: string;

  /**  */
  displayName?: string;

  /**  */
  type?: EnumJiraMetaFieldsSchemaType;

  /** Provided only for type=ARRAY */
  items?: EnumJiraMetaFieldsSchemaItems;

  /** List of available objects (selected object can be used as default value for field) */
  allowedValues?: object[];

  /** If possible, we provide object marked as default value on JIRA side */
  defaultValue?: object;
}

export interface JiraCommentSchema {
  /**  */
  id?: string;

  /**  */
  externalID?: string;

  /**  */
  type?: EnumJiraCommentSchemaType;

  /**  */
  exportRecordID?: string;

  /**  */
  body?: string;

  /**  */
  author?: string;

  /** Filled for the local comment */
  localUserID?: string;

  /**  */
  status?: EnumJiraCommentSchemaStatus;

  /**  */
  created?: string;

  /**  */
  updated?: string;

  /**  */
  deleted?: string;
}

export interface IntegrationServerBaseSchema {
  /**  */
  id: string;

  /**  */
  name: string;

  /**  */
  hostname: string;

  /**  */
  created: Date;

  /** Absolute path for current integration server's webhook It can be used for building webhook URL, like https:\/\/apiary-deck-url\/api\/<webhook> */
  webhook: string;

  /** Token that using in the webhook url */
  token?: string;
}

export interface IntegrationServerSchema {}

export interface IntegrationServerDetailsSchema {}

export interface CommonFileInfoSchema {
  /** File ID (internal or external) */
  uuid?: string;

  /** Original file name */
  fileName?: string;

  /** File MIME type */
  mimeType?: string;

  /** file's checksum. SHA256 by default. May use different algorithms in the future. */
  checksum?: string;

  /** File size */
  size?: number;
}

export interface IssueAttachmentSchema {}

export interface DashboardSchema {
  /** Various client-side card meta information (position, style etc.) */
  metaData?: object;

  /** List of dashboard cards */
  cards?: DashboardCardSchema[];
}

export interface DashboardCardSchema {
  /** Card ID. */
  id?: number;

  /** Custom card name */
  name?: string;

  /**  Object containing various query strings <\/br> Possible query's fields: <\/br> - ips <\/br> - hostnames <\/br> - status <\/br> - projectID (list of projects' ids) <\/br> - groups (list of groups' ids) <\/br> - overdue (true | false | no-sla): <\/br> - isCompleted (true | false): <\/br> - ... (built-in fields from issue) <\/br>  */
  query?: object;

  /** Card type */
  cardType?: EnumDashboardCardSchemaCardType;

  /** Various client-side card meta information (position, style etc.) */
  metaData?: object;
}

export interface DashboardCardDraftSchema {
  /** Object containing various query strings */
  query?: object;

  /** Card type */
  cardType?: EnumDashboardCardDraftSchemaCardType;
}

export interface DashboardCardWithValueSchema {
  /** Card ID. */
  id?: number;

  /** Custom card name */
  name?: string;

  /** Various filter query result based on cardType */
  value?: object;
}

export interface BasicIssueSchema {
  /** Vulnerability name. */
  name?: string;

  /** Internal instance ID. */
  id?: string;

  /**  */
  projectID?: string;

  /** Issue status from predefined project settings */
  status?: string;

  /** Sla date for fixing this issue */
  sla?: string;

  /** Datetime in that was detected SLA changes by user (issue's PATCH) */
  slaManualSet?: string;

  /** Issue file attachments */
  attachments?: IssueAttachmentSchema[];

  /** List of records related to external services export */
  exportRecords?: IssueExportSchema[];

  /** IP addresses of vulnerable asset. */
  ips?: string[];

  /** Hostnames of vulnerable asset. */
  hostnames?: string[];

  /**  */
  requests?: IssueRequestRecordSchema[];

  /** Creation datetime */
  created?: Date;

  /** Completed datetime (time of changing issue to a final status) */
  completed?: Date;
}

export interface CrossProjectIssueSchema {}

export interface IssueStatusSchema {
  /** Status value in lowercase used as ID. */
  name?: string;

  /** Status readable value. */
  displayName?: string;

  /** True if status in predefined in code */
  system?: boolean;

  /** Status creator login */
  author?: string;

  /** Status synchronization ability */
  syncing?: EnumIssueStatusSchemaSyncing;

  /** Status displaying color */
  color?: string;

  /** Status displaying icon */
  icon?: string;

  /** Status displaying order */
  listOrder?: number;
}

export interface IssueStatusChangeEntrySchema {
  /**  */
  oldValue?: string;

  /**  */
  newValue?: string;

  /**  */
  author?: string;

  /**  */
  source?: EnumIssueStatusChangeEntrySchemaSource;

  /**  */
  datetime?: Date;
}

export interface IssueStatusPatchSchema {
  /** Status value in lowercase used as ID (can be set only in separated request) */
  status?: string;

  /** Custom SLA date for current issue  (null will clear sla for an issue) */
  sla?: Date;

  /** Call to action for recalculating the SLA value, basing on SLA-configs (by project or global) */
  slaRecalculate?: boolean;
}

export interface IssueRequestRecordSchema {
  /** HTTP request payload */
  request?: string;

  /** HTTP response payload */
  response?: string;
}

export interface IssueCustomSchema {
  /** Custom schema name */
  name?: string;

  /** Custom schema description */
  description?: string;

  /** Fields display order for front-end */
  order?: string[];

  /** List of custom fields */
  additionalFieldsSettings?: IssueCustomFieldSchema[];

  /** List of custom fields */
  predefinedFieldsSettings?: IssueCustomFieldSchema[];
}

export interface IssueCustomFieldSchema {
  /** Field slugify name */
  name?: string;

  /** Field displaying name */
  displayName?: string;

  /** True if field is mandatory for issue schema */
  required?: boolean;

  /** Field value type from predefined enum */
  type?: EnumIssueCustomFieldSchemaType;

  /** True if field should not be displayed on front-end. */
  hidden?: boolean;

  /** Array of allowed field values */
  allowedValues?: IssueFieldAllowedValue[];

  /** True if field is a list of items */
  isList?: boolean;

  /** Various field metadata in json format */
  metadata?: object;
}

export interface IssueFieldAllowedValue {
  /** Field displayable value */
  display_value?: string;

  /**  */
  value?: AnyValue;
}

export interface AnyValue {}

export interface LoginRequestSchema {
  /** User login */
  userLogin?: string;

  /** User password to login */
  userPassword?: string;

  /** Mfa token for login */
  mfaToken?: string;
}

export interface MfaConfirmation {
  /** Mfa token for login */
  mfaToken: string;
}

export interface UserRegistrationSchema {
  /** User login to register */
  userLogin?: string;

  /** User password to register */
  userPassword?: string;

  /** User email */
  userEmail?: string;

  /** User first name */
  firstName?: string;

  /** User last name */
  lastName?: string;

  /**  */
  role?: UserRoleValueSchema;
}

export interface BotUserRegistrationSchema {
  /** Bot user login to register */
  userLogin?: string;

  /**  */
  role?: UserRoleValueSchema;
}

export interface BotUserSchema {
  /** uuid of user */
  id?: string;

  /** Bot user login to register */
  userLogin?: string;

  /**  */
  role?: UserRoleValueSchema;

  /**  */
  permissions?: UserPermissionValueSchema[];
}

export interface BotUserTokenSchema {
  /** uuid of user */
  id?: string;

  /** JWT token */
  token?: string;
}

export interface UserMFASchema {
  /** base32 mfa key to be used to generate tokens for future use */
  base32?: string;

  /** url to save base32 key */
  otpauthUrl?: string;
}

export interface UserShortSchema {
  /**  */
  id?: string;

  /**  */
  userLogin?: string;

  /**  */
  userEmail?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /** True if user should change his password */
  passwordChangeRequired?: boolean;

  /**  */
  role?: UserRoleValueSchema;

  /**  */
  permissions?: UserPermissionValueSchema[];
}

export interface UserRoleSchema {
  /**  */
  name?: UserRoleValueSchema;

  /**  */
  displayName?: string;

  /**  */
  permissions?: UserPermissionValueSchema[];
}

export interface NotAccessibleByLicenseSchema {
  /**  */
  detail?: string;

  /**  */
  status?: number;

  /**  */
  title?: string;

  /**  */
  type?: string;
}

export interface LicenseSchema {
  /**  */
  customerName?: string;

  /**  */
  endDate?: string;

  /**  */
  id?: string;

  /**  */
  isNotExpired?: boolean;

  /**  */
  isValid?: boolean;

  /**  */
  licenseType?: string;

  /**  */
  productName?: string;

  /**  */
  startDate?: string;

  /**  */
  userCount?: number;

  /**  */
  projectCount?: number;

  /**  */
  version?: number;
}

export interface InformationParameterSchema {
  /**  */
  currentCount?: number;

  /**  */
  isAvailable?: boolean;

  /**  */
  isVisible?: boolean;

  /**  */
  maxCount?: number;
}

export interface LicenseInformationSchema {
  /**  */
  commonParameters?: LicenseInformationSchema_commonParameters;

  /**  */
  projectParameters?: object;
}

export interface UserAdminSchema {
  /**  */
  id?: string;

  /**  */
  userLogin?: string;

  /**  */
  userEmail?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  registered?: Date;

  /** User account activation state */
  state?: EnumUserAdminSchemaState;

  /**  */
  role?: UserRoleValueSchema;

  /**  */
  permissions?: UserPermissionValueSchema[];

  /** True if user should change his password */
  passwordChangeRequired?: boolean;

  /** New user password */
  userPassword?: string;

  /** User account type (LOCAL or LDAP) */
  userType?: string;

  /** true if user confirmed mfa, null if user is not admin and looking another user */
  mfaConfirmed?: boolean;

  /**  */
  userGroups?: UserGroupSchema[];
}

export interface ProjectPermissionViewSchema {
  /**  */
  level?: string;

  /**  */
  user?: UserShortSchema;

  /**  */
  issues?: string[];
}

export interface ProjectPermissionSetSchema {
  /**  */
  userID?: string;

  /**  */
  level?: EnumProjectPermissionSetSchemaLevel;
}

export interface ProjectPermissionUpdateSchema {
  /**  */
  userID?: string;

  /**  */
  level?: EnumProjectPermissionUpdateSchemaLevel;
}

export interface ProjectGroupSchema {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  parentID?: string;

  /**  */
  created?: Date;

  /**  */
  updated?: Date;

  /** Projects of this group(ref ProjectSchema) */
  projects?: [];

  /** List of children groups(ref ProjectGroupSchema) */
  children?: [];

  /** Batch insert a projects into the project group([{id "10efd5d8-fb72-401a-b716-94fad9dbad58"}, ...]) */
  projectsBatch?: [];

  /** Batch insert a children project groups into the project group([{id "10efd5d8-fb72-401a-b716-94fad9dbad58"}, ...]) */
  childrenBatch?: [];
}

export interface UserGroupSchema {
  /**  */
  id?: string;

  /** User group name */
  name?: string;

  /** User group description */
  description?: string;

  /** User group creation date */
  created?: Date;

  /** User group updating date */
  updated?: Date;
}

export interface UserForUserGroupSchema {}

export interface UserGroupResponseSchema {}

export interface SetUnsetUsersIntoUserGroupSchema {
  /**  */
  users: string[];
}

export interface SetUnsetUserGroupsForUserSchema {
  /**  */
  userGroups: string[];
}

export interface UserGroupCreateEditSchema {
  /** User group name */
  name?: string;

  /** User group description */
  description?: string;
}

export interface IssuePermissionViewSchema {
  /**  */
  level?: string;

  /**  */
  user?: UserShortSchema;
}

export interface IssuePermissionSetSchema {
  /**  */
  userID?: string;

  /**  */
  level?: string;
}

export interface IssuePermissionUpdateSchema {
  /**  */
  userID?: string;

  /**  */
  level?: string;
}

export interface IssuePermissionsBulkSetSchema {
  /**  */
  userID: string;

  /**  */
  level: EnumIssuePermissionsBulkSetSchemaLevel;

  /**  */
  issues: string[];
}

export interface IssuePermissionsBulkDeleteSchema {
  /**  */
  userID: string;

  /**  */
  issues: string[];
}

export interface UserNotificationSettingsSchema {
  /**  */
  webEnabled?: boolean;

  /**  */
  webNewIssue?: boolean;

  /**  */
  webNewIssueChatMessage?: boolean;

  /**  */
  webIssueStatusChange?: boolean;

  /** Available only "Enterprise" plan license */
  webCreatedJiraComment?: boolean;

  /** Available only "Enterprise" plan license */
  webUpdatedJiraComment?: boolean;

  /** Available only "Enterprise" plan license */
  webDeletedJiraComment?: boolean;

  /**  */
  emailEnabled?: boolean;

  /**  */
  emailNewIssue?: boolean;

  /**  */
  emailIssueChatMessage?: boolean;

  /**  */
  emailIssueStatusChange?: boolean;

  /** Available only "Enterprise" plan license */
  emailCreatedJiraComment?: boolean;

  /** Available only "Enterprise" plan license */
  emailUpdatedJiraComment?: boolean;

  /** Available only "Enterprise" plan license */
  emailDeletedJiraComment?: boolean;
}

export interface SystemWideSettingsSchema {
  /** If this is set to true, all users without set mfa will lose their session. When this happens users will be unable to login without setting up mfa.
   */
  mfaRequired?: boolean;
}

export interface NotificationEventSchema {
  /** Notification ID in UUID4 format. */
  id?: string;

  /** Notification type based on enum values. */
  eventType?: EnumNotificationEventSchemaEventType;

  /** Notification localized headline. */
  message?: string;

  /** Various notification metadata for front-end purposes. */
  metaData?: object;

  /** Notification creation datetime */
  created?: Date;

  /** false for new notifications, true for notifications that marked as read */
  read?: boolean;

  /** Corresponding project ID. */
  projectID?: string;
}

export interface ConveyReportSchema {
  /** AMQP message metadata */
  messageInfo?: object;

  /** AMQP message body */
  messageID?: string;

  /** Convey error log message */
  errorMessage?: string;

  /** Convey open error code */
  errorCode?: IConveyReportSchemaErrorCode;

  /** Hawser Connection ID */
  hawserID?: string;
}

export interface ConveyMessageSchema {
  /** Hawser Connection ID */
  hawser_id?: string;

  /** Data transfer flow status */
  flow_status?: EnumConveyMessageSchemaFlowStatus;

  /** Data transfer type */
  flow_action?: EnumConveyMessageSchemaFlowAction;

  /** Data transfer request internal ID */
  record_id?: string;

  /** AMQP message payload in JSON format */
  body?: object;
}

export interface ChatMessagePostSchema {
  /** Actual message body */
  text?: string;

  /** Message ID to reply */
  parentID?: string;
}

export interface ChatMessageViewSchema {
  /** Message ID */
  id?: string;

  /** Message author name */
  author?: string;

  /** Message creation datetime */
  timestamp?: Date;

  /** Actual message body */
  text?: string;

  /** Message delivery status */
  status?: EnumChatMessageViewSchemaStatus;

  /** Primary server component source for message */
  sourceType?: EnumChatMessageViewSchemaSourceType;

  /** Message replies */
  children?: [];
}

export interface LDAPAuthenticationConfigSchema {
  /**  */
  protocol?: EnumLDAPAuthenticationConfigSchemaProtocol;

  /**  */
  host?: string;

  /**  */
  port?: number;

  /**  */
  baseDN?: string;

  /**  */
  adminLogin?: string;

  /**  */
  adminPassword?: string;

  /**  */
  adminGroupDN?: string;

  /**  */
  editorGroupDN?: string;

  /**  */
  readonlyGroupDN?: string;

  /**  */
  blockedGroupDN?: string;

  /**  */
  userFilter?: string;

  /**  */
  objectClass?: string;

  /**  */
  loginAttribute?: string;

  /**  */
  emailAttribute?: string;

  /**  */
  firstNameAttribute?: string;

  /**  */
  lastNameAttribute?: string;

  /**  */
  enabled?: boolean;
}

export interface LDAPUserSchema {
  /**  */
  login?: string;

  /**  */
  email?: string;

  /**  */
  dn?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  groups?: string[];

  /**  */
  isAdmin?: boolean;

  /**  */
  isBlocked?: boolean;
}

export interface ReportTemplateSchema {}

export interface IssueStatusTemplateSchema {
  /** Status template base ID in slug format */
  name?: string;

  /** Status template displaying name */
  displayName?: string;

  /** True if template is created by vendor */
  system?: boolean;

  /** Status displaying color */
  color?: string;

  /** Status displaying icon */
  icon?: string;

  /** Status displaying order */
  listOrder?: number;
}

export interface SLASettingSchema {
  /**  */
  SLADaysDefault?: number;

  /**  */
  SLADaysInfo?: number;

  /**  */
  SLADaysLow?: number;

  /**  */
  SLADaysMedium?: number;

  /**  */
  SLADaysHigh?: number;

  /**  */
  SLADaysCritical?: number;

  /** Recalculate all in-progress issues for current scope (global on in-project) */
  recalculateSLAForIssues?: boolean;
}

export interface SLASettingWithDefaultSchema {
  /**  */
  actual?: SLASettingSchema;

  /**  */
  default?: SLASettingSchema;
}

export interface UUIDSchema {}

export interface NotAccessibleByLicenseExampleResponseSchema {}

export interface SmtpConfigSchema {
  /**  */
  host?: string;

  /**  */
  port?: number;

  /**  */
  username?: string;

  /**  */
  password?: string;

  /**  */
  fromAddress?: string;

  /**  */
  connectionSecurity?: EnumSmtpConfigSchemaConnectionSecurity;

  /**  */
  enabled?: boolean;
}

export interface SmtpConfigSchemaResponse {}

export interface EmailSchema {
  /**  */
  toAddress?: string;

  /**  */
  subject?: string;

  /**  */
  message?: string;
}

export interface IssuesFilterSchema {
  /**  */
  status?: EnumIssuesFilterSchemaStatus[];

  /**  */
  totalScore?: TotalScoreSchema[];

  /**  */
  probabilityScore?: ScoreSchema[];

  /**  */
  criticalityScore?: ScoreSchema[];

  /**  */
  overdue?: EnumIssuesFilterSchemaOverdue[];

  /**  */
  isCompleted?: EnumIssuesFilterSchemaIsCompleted[];

  /** List of selected project IDs */
  projectID?: string[];

  /** List of selected group IDs (all projects in all sub-groups will be included) */
  groups?: string[];

  /**  */
  ips?: string[];

  /**  */
  hostnames?: string[];

  /**  */
  ports?: number[];

  /**  */
  protocols?: string[];

  /**  */
  paths?: string[];

  /**  */
  optionals?: string[];
}

export interface DialectSettingsSchema {
  /**  */
  dialect?: ExportCSVDialects;

  /**  */
  dialectDisplayName?: string;

  /**  */
  encoding?: ExportCSVEncodings;

  /**  */
  delimiter?: ExportCSVDelimiters;

  /**  */
  doubleQuote?: boolean;

  /**  */
  lineTerminator?: string;

  /**  */
  quoteChar?: string;

  /**  */
  escapeChar?: string;

  /**  */
  quotingFields?: ExportCSVQuoting;
}

export interface ExportCSVMetaInfoResponse {
  /** Count of all found issues for specified params */
  totalCount?: number;

  /** Max count of issues, that will be exported to */
  maxExportCount?: number;

  /**  */
  supportedEncodings?: ExportCSVEncodings[];

  /**  */
  dialectsSettings?: DialectSettingsSchema[];
}

export interface ExportCSVMetaRequestSchema {
  /**  */
  exportType?: EnumExportCSVMetaRequestSchemaExportType;

  /** If specified, issues will be filtered by projectID */
  projectID?: string;

  /** Query for searching issues (aka query for dashboard's cards) */
  filterQuery?: string;

  /**  */
  issues?: string[];
}

export interface ExportCSVRequestSchema {}

export interface ApplicationConnectBaseSchema {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  type?: EnumApplicationConnectBaseSchemaType;

  /**  */
  created?: Date;

  /**  */
  updated?: Date;

  /**  */
  lastOutgoingPing?: Date;

  /**  */
  lastIncomingPong?: Date;
}

export interface ApplicationConnectWithAggregationSchema {}

export interface UpdatingApplicationConnectSchema {
  /**  */
  name?: string;
}

export interface AppConnectResourceUsersSchema {
  /**  */
  status?: EnumAppConnectResourceUsersSchemaStatus;

  /**  */
  data?: AppConnectResourceUsersSchema_data[];

  /**  */
  error_details?: string;
}

export interface RemoteUserProjectPermissionSchema {
  /**  */
  login?: string;

  /**  */
  level?: EnumRemoteUserProjectPermissionSchemaLevel;
}

export interface AppConnectProjectSchema {
  /**  */
  projectData?: ProjectSchema;

  /**  */
  localUsers?: ProjectPermissionSetSchema[];

  /**  */
  remoteUsers?: RemoteUserProjectPermissionSchema[];
}

export interface HawserStatusSchema {
  /**  */
  statusCode?: number;

  /**  */
  statusDescription?: string;
}

export interface inline_response_200 {}

export interface body {
  /**  */
  connection_type?: EnumbodyConnectionType;

  /**  */
  connection_name?: string;

  /**  */
  config?: string;
}

export interface body_1 {
  /**  */
  issues: string[];
}

export interface inline_response_200_1 {
  /**  */
  items?: CrossProjectIssueSchema[];

  /** There are some items for the next page */
  hasNext?: boolean;

  /** Current offset applied for paginated response */
  offset?: number;

  /** Total count of items (without limit offset filtering) */
  totalCount?: number;
}

export interface inline_response_200_2 {
  /**  */
  totalCount?: number;

  /**  */
  items?: LDAPUserSchema[];
}

export interface body_2 {
  /**  */
  template: string;
}

export interface body_3 {
  /**  */
  license_file: string;
}

export interface body_4 {
  /**  */
  name?: string;

  /**  */
  type: Enumbody_4Type;

  /**  */
  config?: string;
}

export interface JiraIntegrationSchema_jiraIssueType {
  /**  */
  id?: string;

  /**  */
  name?: string;
}

export interface JiraIntegrationSchema_backwardStatusMapping {
  /** Local statusName for matching with external One (can be given through "\/projects\/{projectID}\/status\/" API) */
  localStatus: string;

  /** External statusID from JIRA application for matching with local status (can be given through "\/integrations\/jira\/{integrationID}\/meta\/" API) */
  externalStatusID: string;
}

export interface JiraIntegrationSchema_fieldsDefaultValues {
  /**  */
  field: string;

  /**  */
  value: AnyValue;
}

export interface JiraIntegrationExportSchema_fieldsValues {
  /**  */
  field: string;

  /**  */
  value: AnyValue;
}

export interface JiraIntegrationExportSchema_issues {
  /** Issue id */
  id?: string;

  /** list of dictionaries filling default fields */
  fieldsValues?: JiraIntegrationExportSchema_fieldsValues[];
}

export interface JiraIntegrationExportSchema_fieldsDefaultValues {
  /**  */
  field?: string;

  /**  */
  value?: AnyValue;
}

export interface JiraWebhookRequestSchema_issue_fields_status {
  /**  */
  id?: string;

  /**  */
  name?: string;
}

export interface JiraWebhookRequestSchema_issue_fields {
  /**  */
  status?: JiraWebhookRequestSchema_issue_fields_status;
}

export interface JiraWebhookRequestSchema_issue {
  /**  */
  id?: string;

  /**  */
  key?: string;

  /**  */
  fields?: JiraWebhookRequestSchema_issue_fields;
}

export interface JiraWebhookRequestSchema_comment_author {
  /**  */
  name?: string;

  /**  */
  emailAddress?: string;

  /**  */
  displayName?: string;

  /**  */
  key?: string;

  /**  */
  active?: boolean;

  /**  */
  accountId?: string;
}

export interface JiraWebhookRequestSchema_comment {
  /**  */
  id?: string;

  /**  */
  body?: string;

  /**  */
  author?: JiraWebhookRequestSchema_comment_author;
}

export interface JiraMetaInfoResponseSchema_issuetypes {
  /**  */
  id: string;

  /**  */
  name: string;

  /**  */
  description?: string;

  /**  */
  iconUrl?: string;
}

export interface JiraMetaInfoResponseSchema_projects {
  /**  */
  key: string;

  /**  */
  name: string;

  /**  */
  issuetypes?: JiraMetaInfoResponseSchema_issuetypes[];
}

export interface JiraMetaInfoResponseSchema_externalStatuses {
  /**  */
  id: string;

  /**  */
  name: string;
}

export interface IntegrationServerDetailsSchema_project {
  /**  */
  id?: string;

  /**  */
  name?: string;
}

export interface IntegrationServerDetailsSchema_integrations {
  /**  */
  id?: string;

  /**  */
  type?: string;

  /**  */
  name?: string;

  /**  */
  project?: IntegrationServerDetailsSchema_project;
}

export interface LicenseInformationSchema_commonParameters {
  /**  */
  jira?: InformationParameterSchema;

  /**  */
  ldap?: InformationParameterSchema;

  /**  */
  project?: InformationParameterSchema;

  /**  */
  user?: InformationParameterSchema;

  /**  */
  botUser?: InformationParameterSchema;

  /**  */
  mfa?: InformationParameterSchema;

  /**  */
  crossProjectDashboard?: InformationParameterSchema;

  /**  */
  applicationConnect?: InformationParameterSchema;
}

export interface AppConnectResourceUsersSchema_data {
  /**  */
  id?: string;

  /**  */
  userLogin?: string;

  /**  */
  userEmail?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /** Flag if the user exists locally */
  isExisting?: boolean;

  /** Flag if the user is blocked */
  isBlocked?: boolean;

  /** Flag if the user is admin */
  isAdmin?: boolean;
}
export enum EnumProjectIntegrationSchemaIntegrationType {
  'JIRA' = 'JIRA'
}
export enum EnumExportedIssuesSchemaState {
  'SUCCESS' = 'SUCCESS',
  'FAILED' = 'FAILED',
  'ATTACHMENT_FAILED' = 'ATTACHMENT_FAILED'
}
export enum EnumJiraApplicationInfoResponseSchemaAuthentication {
  'CLOUD' = 'CLOUD',
  'BASIC' = 'BASIC',
  'TOKEN' = 'TOKEN'
}
export enum EnumJiraMetaFieldsSchemaType {
  'STRING' = 'STRING',
  'ENUM' = 'ENUM',
  'DATE' = 'DATE',
  'ARRAY' = 'ARRAY',
  'SEARCH_USER' = 'SEARCH_USER',
  'SEARCH_EPIC' = 'SEARCH_EPIC',
  'SEARCH_SPRINT' = 'SEARCH_SPRINT',
  'UNSUPPORTED' = 'UNSUPPORTED'
}
export enum EnumJiraMetaFieldsSchemaItems {
  'STRING' = 'STRING',
  'ENUM' = 'ENUM',
  'DATE' = 'DATE',
  'SEARCH_USER' = 'SEARCH_USER',
  'SEARCH_EPIC' = 'SEARCH_EPIC',
  'UNSUPPORTED' = 'UNSUPPORTED'
}
export enum EnumJiraCommentSchemaType {
  'LOCAL' = 'LOCAL',
  'JIRA' = 'JIRA'
}
export enum EnumJiraCommentSchemaStatus {
  'CREATED' = 'CREATED',
  'SYNCED' = 'SYNCED',
  'SYNC_ERROR' = 'SYNC_ERROR'
}
export enum EnumDashboardCardSchemaCardType {
  'counter' = 'counter',
  'ab' = 'ab',
  'pie_chart' = 'pie_chart',
  'table' = 'table',
  'checklist_status' = 'checklist_status'
}
export enum EnumDashboardCardDraftSchemaCardType {
  'counter' = 'counter',
  'ab' = 'ab',
  'pie_chart' = 'pie_chart',
  'table' = 'table',
  'checklist_status' = 'checklist_status'
}
export enum EnumIssueStatusSchemaSyncing {
  'IN' = 'IN',
  'OUT' = 'OUT',
  'NO' = 'NO'
}
export enum EnumIssueStatusChangeEntrySchemaSource {
  'local' = 'local',
  'external' = 'external'
}
export enum EnumIssueCustomFieldSchemaType {
  'ASSET' = 'ASSET',
  'TEXT' = 'TEXT',
  'TEXT_MD' = 'TEXT_MD',
  'CVSS_BASE_VECTOR' = 'CVSS_BASE_VECTOR',
  'CVSS_BASE_SCORE' = 'CVSS_BASE_SCORE',
  'STATUS' = 'STATUS',
  'INTEGER' = 'INTEGER',
  'FLOAT' = 'FLOAT',
  'BOOLEAN' = 'BOOLEAN',
  'DATE' = 'DATE',
  'DATETIME' = 'DATETIME',
  'UUID' = 'UUID',
  'IP' = 'IP',
  'HOSTNAME' = 'HOSTNAME',
  'REQUEST' = 'REQUEST',
  'BASE_SCORE' = 'BASE_SCORE',
  'DATASOURCE' = 'DATASOURCE'
}
export enum UserRoleValueSchema {
  'ADMIN' = 'ADMIN',
  'EDITOR' = 'EDITOR',
  'READONLY' = 'READONLY'
}

export enum UserPermissionValueSchema {
  'ALL' = 'ALL',
  'PROJECT_CREATE' = 'PROJECT_CREATE',
  'BOT_USER_READ' = 'BOT_USER_READ',
  'BOT_USER_CREATE' = 'BOT_USER_CREATE',
  'BOT_USER_EDIT' = 'BOT_USER_EDIT',
  'ADMIN_SPACE_WRITE' = 'ADMIN_SPACE_WRITE',
  'ADMIN_INTEGRATION_SERVER_READ' = 'ADMIN_INTEGRATION_SERVER_READ',
  'ADMIN_INTEGRATION_SERVER_WRITE' = 'ADMIN_INTEGRATION_SERVER_WRITE',
  'ADMIN_INTEGRATION_READ' = 'ADMIN_INTEGRATION_READ',
  'ADMIN_INTEGRATION_WRITE' = 'ADMIN_INTEGRATION_WRITE',
  'ADMIN_BACKGROUND_TASKS_READ' = 'ADMIN_BACKGROUND_TASKS_READ',
  'ADMIN_BACKGROUND_TASKS_WRITE' = 'ADMIN_BACKGROUND_TASKS_WRITE',
  'ADMIN_LDAP_READ' = 'ADMIN_LDAP_READ',
  'ADMIN_LDAP_WRITE' = 'ADMIN_LDAP_WRITE',
  'ADMIN_SMTP_READ' = 'ADMIN_SMTP_READ',
  'ADMIN_SMTP_WRITE' = 'ADMIN_SMTP_WRITE',
  'ADMIN_SYSTEM_STATUS_READ' = 'ADMIN_SYSTEM_STATUS_READ',
  'ADMIN_SYSTEM_STATUS_WRITE' = 'ADMIN_SYSTEM_STATUS_WRITE',
  'ADMIN_REPORT_TEMPLATE_WRITE' = 'ADMIN_REPORT_TEMPLATE_WRITE',
  'ADMIN_SYSTEM_SETTINGS_WRITE' = 'ADMIN_SYSTEM_SETTINGS_WRITE',
  'ADMIN_SLA_READ' = 'ADMIN_SLA_READ',
  'ADMIN_SLA_WRITE' = 'ADMIN_SLA_WRITE',
  'ADMIN_LICENSE_WRITE' = 'ADMIN_LICENSE_WRITE',
  'ADMIN_USER_READ' = 'ADMIN_USER_READ',
  'ADMIN_USER_WRITE' = 'ADMIN_USER_WRITE'
}
export enum EnumUserAdminSchemaState {
  'ACTIVE' = 'ACTIVE',
  'PENDING' = 'PENDING',
  'BLOCKED' = 'BLOCKED'
}
export enum EnumProjectPermissionSetSchemaLevel {
  'READONLY' = 'READONLY',
  'LIMITED' = 'LIMITED',
  'EDITOR' = 'EDITOR',
  'OWNER' = 'OWNER'
}
export enum EnumProjectPermissionUpdateSchemaLevel {
  'READONLY' = 'READONLY',
  'LIMITED' = 'LIMITED',
  'EDITOR' = 'EDITOR',
  'OWNER' = 'OWNER'
}
export enum EnumIssuePermissionsBulkSetSchemaLevel {
  'READ' = 'READ',
  'EDIT' = 'EDIT'
}
export enum EnumNotificationEventSchemaEventType {
  'NEW_ISSUE' = 'NEW_ISSUE',
  ' NEW_CHAT_MESSAGE' = ' NEW_CHAT_MESSAGE',
  'ISSUE_STATUS_CHANGE' = 'ISSUE_STATUS_CHANGE'
}
type IConveyReportSchemaErrorCode = 10 | 20 | 30 | 40;
export enum EnumConveyMessageSchemaFlowStatus {
  'request' = 'request',
  'confirm' = 'confirm',
  'error' = 'error'
}
export enum EnumConveyMessageSchemaFlowAction {
  'issue_put' = 'issue_put',
  'issue_message_add' = 'issue_message_add',
  'issue_schema_set' = 'issue_schema_set',
  'project_checklist_put' = 'project_checklist_put',
  'project_put' = 'project_put',
  'resource_users_list_get' = 'resource_users_list_get'
}
export enum EnumChatMessageViewSchemaStatus {
  'new' = 'new',
  'sent' = 'sent',
  'delivered' = 'delivered',
  'failed' = 'failed'
}
export enum EnumChatMessageViewSchemaSourceType {
  'frigate' = 'frigate',
  'brig' = 'brig'
}
export enum EnumLDAPAuthenticationConfigSchemaProtocol {
  'ldap' = 'ldap',
  'ldaps' = 'ldaps'
}
export enum EnumSmtpConfigSchemaConnectionSecurity {
  'NONE' = 'NONE',
  'STARTTLS' = 'STARTTLS',
  'SSL/TLS' = 'SSL/TLS'
}
export type TotalScoreSchema = 0 | 1 | 2 | 3 | 4;

export type ScoreSchema = 1 | 2 | 3;
export enum EnumIssuesFilterSchemaStatus {
  'new_issue' = 'new_issue',
  'retest_required' = 'retest_required',
  'fix_confirmed' = 'fix_confirmed',
  'fix_unconfirmed' = 'fix_unconfirmed'
}
export enum EnumIssuesFilterSchemaOverdue {
  'true' = 'true',
  'false' = 'false',
  'no-sla' = 'no-sla'
}
export enum EnumIssuesFilterSchemaIsCompleted {
  'true' = 'true',
  'false' = 'false'
}
export enum ExportCSVDelimiters {
  'SEMICOLON' = 'SEMICOLON',
  'COMMA' = 'COMMA',
  'TAB' = 'TAB',
  'SPACE' = 'SPACE'
}

export enum ExportCSVQuoting {
  'QUOTE_ALL' = 'QUOTE_ALL',
  'QUOTE_NONE' = 'QUOTE_NONE',
  'QUOTE_MINIMAL' = 'QUOTE_MINIMAL',
  'QUOTE_NONNUMERIC' = 'QUOTE_NONNUMERIC'
}

export enum ExportCSVEncodings {
  'UTF-8' = 'UTF-8'
}

export enum ExportCSVDialects {
  'EXCEL' = 'EXCEL',
  'GOOGLE_DOCS' = 'GOOGLE_DOCS'
}
export enum EnumExportCSVMetaRequestSchemaExportType {
  'CSV' = 'CSV'
}
export enum EnumApplicationConnectBaseSchemaType {
  'PRIMARY' = 'PRIMARY',
  'SECONDARY' = 'SECONDARY'
}
export enum EnumAppConnectResourceUsersSchemaStatus {
  'PENDING' = 'PENDING',
  'READY' = 'READY',
  'ERROR' = 'ERROR'
}
export enum EnumRemoteUserProjectPermissionSchemaLevel {
  'READONLY' = 'READONLY',
  'EDIT' = 'EDIT',
  'OWNER' = 'OWNER'
}
export enum EnumbodyConnectionType {
  'new' = 'new',
  'existing' = 'existing'
}
export enum Enumbody_4Type {
  'PRIMARY' = 'PRIMARY',
  'SECONDARY' = 'SECONDARY'
}
